import { extendTheme } from "@chakra-ui/react";

export const theme = extendTheme({
  fonts: {
    body: "Montserrat",
  },
  colors: {
    brand: {
      primary: "#7F2A93",
      secondary: "#FFBB43",
      white: "#ffffff",
      snow: "#F4F4FC",
    },
    breakpoints: {
      sm: '320px',
      md: '640px',
      lg: '960px',
      xl: '1200px',
      '2xl': '1536px',
    }
  },
  styles: {
    global: () => ({
      '.swiper': {
        height: '400px',
        width: ["", "1200px"]
      },
      '.swiper-slide': {
        display: "flex",
        justifyContent: "center"
      },
      '.swiper-button-next, .swiper-button-prev': {
        color: "#7F2A93",
        border: "1px solid",
        borderColor: "#7F2A93",
        width: "44px",
        marginX: "1px",
        borderRadius: "100%"
      },
      '.swiper-button-next:after, .swiper-button-prev:after': {
        fontSize: "14px"
      },
      '.swiper-pagination-bullet': {
        border: "2px solid",
        width: 4,
        height: 4,
        borderColor: "#7F2A93",
      },
      '.swiper-pagination-bullet-active': {
        backgroundColor: "#FFBB43",
      },
      '.swiper-pagination': {
        marginTop: 20,
      },
      '.youtube-video': {
        aspectRatio: 16 / 9,
        width: "100%",
        maxWidth: "1280px",
        height: ["400px", "400px", "720px"],
      }
    }),
  },
});