import {
  HStack,
  Image,
  Text,
  VStack,
  StackProps,
  Grid,
  Link,
} from "@chakra-ui/react";

export const Header = ({ ...rest }: StackProps) => {
  return (
    <Grid gridTemplateColumns="42% 58%" {...rest}>
      <HStack
        h={["254px", "390px"]}
        pl={["0px", "0px", "0px", "0px", "100px"]}
        w={["234px", "334px", "334px", "334px", "fit-content"]}
        pr={["25px", "25px", "25px", "25px", "80px"]}
        alignItems="flex-start"
        justifyContent="center"
        bgColor="brand.primary"
        borderTopRightRadius={["none", "none", "none", "none", "full"]}
        borderBottomRightRadius="full"
        borderBottomLeftRadius={["full", "full", "full", "full", "none"]}
        objectFit="fill"
        zIndex={2}
      >
        <Image
          display={["none", "none", "none", "none", "initial"]}
          src="/images/coin.webp"
        />
        <VStack
          pt={["23px", "39px"]}
          alignItems="start"
          rowGap={["16px", "54px"]}
        >
          <Image src="/images/logo.webp" />
          <Text
            as="span"
            w={["fit-content", "fit-content", "282px"]}
            fontSize={["28px", "40px", "40px", "40px", "40px"]}
            lineHeight={["28px", "40px", "40px", "40px", "45px"]}
            fontWeight="700"
          >
            A moeda <Text>digital que</Text>
            <Text color="brand.secondary">valoriza o fã</Text>
          </Text>
        </VStack>
      </HStack>

      <VStack w="100%" h="120px" alignItems="end">
        <HStack px="335px" py="40px" display={["none", "none", "none", "flex"]}>
          <Link
            justifySelf="end"
            // alignSelf="end"
            href="#download-app"
            width="210px"
            padding="8px 16px"
            borderRadius="full"
            bgColor="brand.primary"
            fontWeight="bold"
            color="brand.secondary"
            textTransform="uppercase"
            _hover={{
              transform: "scale(1.1)",
            }}
          >
            baixe o aplicativo
          </Link>
        </HStack>
      </VStack>

      <Image
        w={["1584px"]}
        h={["393px", "568px"]}
        objectFit={["cover", "cover", "cover", "cover", "contain"]}
        objectPosition="center"
        justifySelf="flex-end"
        position="absolute"
        top={["0px", "0px", "0px", "0px", "120px"]}
        src="/images/bg-gradient.webp"
        zIndex={1}
      />
      <Image
        w={["877px"]}
        h={["212px", "412px", "412px", "412px", "530px"]}
        objectFit={["contain"]}
        objectPosition="center"
        alignSelf="center"
        justifySelf="center"
        position="absolute"
        left={0}
        right={0}
        marginLeft="auto"
        marginRight="auto"
        src="/images/rocker.webp"
        top={["180px", "280px", "180px", "180px", "120px"]}
        zIndex={2}
      />
    </Grid>
  );
};
