import { Flex, StackProps, Text, VStack } from "@chakra-ui/react";

export const WhatIs = ({ ...rest }: StackProps) => {
  return (
    <VStack {...rest}>
      <Text
        fontSize={[28, 45]}
        lineHeight={9}
        fontWeight="bold"
        color="brand.secondary"
      >
        O que é Strings?
      </Text>
      <Text
        as="span"
        fontSize={20}
        lineHeight={1}
        color="brand.primary"
        textAlign="center"
      >
        Assista ao vídeo e<Text> saiba como fuciona.</Text>
      </Text>
      <Flex
        position="relative"
        justifyContent="center"
        alignItems="center"
        width="100%"
        px="10px"
        m={0}
      >
        <iframe
          className="youtube-video"
          src="https://www.youtube.com/embed/8kql3HNjleI"
          title="O que é Strings?"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </Flex>
    </VStack>
  );
};
