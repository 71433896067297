import { Grid, GridItem, Image, Link, Text } from "@chakra-ui/react";

export const Footer = () => {
  return (
    <Grid
      w="100%"
      py="75px"
      px={["30px", "30px", "30px", "30px", "330px"]}
      templateAreas={[
        `"logo"
                      "socials"
                      "infos"
                      "copy"
                    `,
        `"logo"
                                  "socials"
                                  "infos"
                                  "copy"
                                `,
        `"logo"
                                              "socials"
                                              "infos"
                                              "copy"
                                            `,
        `"logo"
                                                          "socials"
                                                          "infos"
                                                          "copy"
                                                        `,
        `"logo ."
                      "infos socials"
                      "infos copy"
                    `,
      ]}
      templateColumns={["", "1fr"]}
      justifyContent="center"
      alignItems="center"
      rowGap="50px"
    >
      <GridItem
        area="logo"
        display="flex"
        justifyContent={[
          "center",
          "center",
          "center",
          "flex-start",
          "flex-start",
        ]}
      >
        <Image src="/images/v-logo.webp" />
      </GridItem>

      <GridItem
        width="272px"
        area="infos"
        display="flex"
        flexDirection="column"
        justifySelf={["center", "center", "center", "auto"]}
        color="brand.primary"
      >
        <Text fontSize={16} fontWeight="black" textTransform="uppercase">
          atendimento
        </Text>
        <Text fontSize={15} fontWeight="normal">
          Horário: das 8h até 18h
        </Text>
        <Text fontSize={15} fontWeight="bold">
          atendimento@stringsbrasil.com
        </Text>
      </GridItem>

      <GridItem
        columnGap="50px"
        area="socials"
        display="flex"
        flexDirection="row"
        justifyContent={["center", "center", "center", "flex-end"]}
      >
        <Link
          target="_blank"
          href="https://www.instagram.com/strings.brasil/"
          _hover={{
            transform: "scale(1.2)",
          }}
        >
          <Image src="/images/instagram.webp" />
        </Link>

        <Link
          target="_blank"
          href="https://www.facebook.com/stringsbrasil"
          _hover={{
            transform: "scale(1.2)",
          }}
        >
          <Image src="/images/facebook.webp" />
        </Link>

        <Link
          target="_blank"
          href="https://www.youtube.com/channel/UCvf22iS1OFu8s-o_wJ4NRCA"
          _hover={{
            transform: "scale(1.2)",
          }}
        >
          <Image src="/images/youtube.webp" />
        </Link>

        <Link
          target="_blank"
          href="https://www.linkedin.com/company/strings-brasil/"
          _hover={{
            transform: "scale(1.2)",
          }}
        >
          <Image src="/images/linkedin.webp" />
        </Link>
      </GridItem>

      <GridItem
        area="copy"
        display="flex"
        justifyContent={["center", "center", "center", "flex-end"]}
      >
        <Text fontSize="15px" textAlign="center" color="brand.primary">
          © Copyright 2022{" "}
          <Text as="span" fontWeight="bold">
            Strings.{" "}
          </Text>
          Todos os direitos reservados.
        </Text>
      </GridItem>
    </Grid>
  );
};
