import * as React from "react";
import { Flex } from "@chakra-ui/react";

import { Header } from "./sections/Header";
import { CallToStoreHeader } from "./sections/CallToStoreHeader";
import { WhatIs } from "./sections/WhatIs";
import { Profits } from "./sections/Profits";
import { CallToStoreFooter } from "./sections/CallToStoreFooter";

import { Fonts } from "./styles/fonts";
import StylesProvider from "./providers/StylesProvider";
import { Footer } from "./sections/Footer";

export const App = () => (
  <StylesProvider>
    <Fonts />
    <Flex
      h="fit-content"
      color="brand.white"
      bgColor="brand.white"
      flexDirection="column"
      overflowX="hidden"
    >
      <Header zIndex={[2]} />
      <CallToStoreHeader zIndex={[2]} />
      <WhatIs pt={[5, 100, 100, 100, 5]} w="100%" />
      <Profits pt={[10, 100]} w="100%" />
      <CallToStoreFooter zIndex={[2]} />
      <Footer />
      {/* <Header />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 /> */}
    </Flex>
  </StylesProvider>
);
