import { ReactNode } from "react";
import { Navigation, Pagination, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Box, Image, Text, useMediaQuery, VStack } from "@chakra-ui/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

export const Carousel = () => {
  const [isLargerThanHD] = useMediaQuery(["(min-width: 1920px)"]);

  const Slide = ({ children }: { children: ReactNode | ReactNode[] }) => (
    <Box
      backgroundColor="brand.snow"
      color="brand.primary"
      style={{
        borderRadius: 43,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "283px",
        height: "340px",
      }}
    >
      <VStack rowGap={3}>
        <Image src="/images/heart-box.webp" />
        {children}
      </VStack>
    </Box>
  );

  return (
    <Box
      w={["100%", "1148px"]}
      position="relative"
      overflowX="unset"
      h="400px"
      py={10}
    >
      <Swiper
        navigation={isLargerThanHD}
        pagination={{
          clickable: true,
        }}
        centeredSlides
        centeredSlidesBounds
        centerInsufficientSlides
        breakpoints={{
          320: {
            slidesPerView: 1,
          },
          640: {
            slidesPerView: 3,
          },
        }}
        modules={[Navigation, Pagination, A11y]}
      >
        <SwiperSlide>
          <Slide>
            <Text w={110} color="brand.primary" textAlign="center">
              Aproveitar o pacote de
              <Text fontWeight="bold">benefícios do artista</Text>
            </Text>
          </Slide>
        </SwiperSlide>
        <SwiperSlide>
          <Slide>
            <Text w={160} color="brand.primary" textAlign="center">
              Comprar ingressos
              <Text fontWeight="bold">antecipadamente</Text>
            </Text>
          </Slide>
        </SwiperSlide>
        <SwiperSlide>
          <Slide>
            <Text w={130} color="brand.primary" textAlign="center">
              Trocar por produtos com
              <Text fontWeight="bold">super desconto</Text>
              <Text>em nossa loja</Text>
            </Text>
          </Slide>
        </SwiperSlide>
      </Swiper>
    </Box>
  );
};
