import { StackProps, Text, VStack } from "@chakra-ui/react";
import { Carousel } from "../components/Carousel";

export const Profits = ({ ...rest }: StackProps) => {
  return (
    <VStack {...rest}>
      <Text
        as="span"
        fontSize={[28, 45]}
        lineHeight={7}
        fontWeight="bold"
        textAlign="center"
        color="brand.secondary"
      >
        O que fazer com
        <Text as="span"> as moedas</Text>
      </Text>
      <Text
        fontSize={20}
        lineHeight={1}
        fontWeight={400}
        color="brand.primary"
        textAlign="center"
      >
        Benefícios exclusivos,
        <Text as="span">experiências inesquecíveis,</Text>
        <Text as="span">produtos,</Text>
        <Text>serviços e vantagens.</Text>
      </Text>

      <Carousel />
    </VStack>
  );
};
