import React from "react";
import { ChakraProvider } from "@chakra-ui/react";

import { Fonts } from "../styles/fonts";
import { theme } from "../styles/theme";

interface Props {
  children: React.ReactNode;
}

export function StylesProvider({ children }: Props) {
  return (
    <ChakraProvider theme={theme}>
      <Fonts />
      {children}
    </ChakraProvider>
  );
}

export default StylesProvider;
