import {
  HStack,
  Text,
  VStack,
  StackProps,
  Image,
  Flex,
  Link,
} from "@chakra-ui/react";

export const CallToStoreHeader = ({ ...rest }: StackProps) => {
  return (
    <Flex
      h="660px"
      mt={["100px", "300px", "300px", "300px", "250px"]}
      flexDirection={["column", "column", "column", "column", "row"]}
      justifyContent="center"
      alignItems="center"
      {...rest}
    >
      <Image
        w={["", "835px"]}
        objectFit="contain"
        objectPosition="center"
        src="/images/mobile-store.webp"
        mr={["100px", "100px", "100px", "100px", "-250px"]}
        zIndex={2}
      />
      <HStack
        minHeight="297px"
        width="100%"
        maxWidth="602px"
        position="relative"
        bgColor="brand.primary"
        borderTopRightRadius={["full"]}
        borderBottomRightRadius={["full"]}
        alignSelf={[
          "flex-start",
          "flex-start",
          "flex-start",
          "flex-start",
          "auto",
        ]}
        objectFit="fill"
        zIndex={1}
      >
        <Image
          w={["367px"]}
          position="absolute"
          top={["-170px"]}
          right="10px"
          objectFit="contain"
          objectPosition="center"
          src="/images/cta-coins.webp"
        />
        <VStack
          pl={["0px", "50px", "152px"]}
          pr="30px"
          alignItems="flex-start"
          rowGap="22px"
        >
          <Text as="span" fontSize="25px" lineHeight="28px" fontWeight="700">
            Participe agora da
            <Text>comunidade Strings.</Text>
          </Text>
          <HStack>
            <VStack alignItems="start">
              <Text
                as="span"
                fontSize="15px"
                lineHeight="14px"
                fontWeight="800"
                textTransform="uppercase"
                color="brand.secondary"
              >
                Baixar o<Text>aplicativo</Text>
              </Text>
              <HStack width="295px">
                <Link
                  href="https://apps.apple.com/br/app/strings/id1659828881"
                  target="_blank"
                  _hover={{
                    transform: "scale(1.05)",
                  }}
                  zIndex={3}
                >
                  <Image src="/images/app-store.webp" />
                </Link>
                <Link
                  href="https://play.google.com/store/apps/details?id=com.stringsbrasil"
                  target="_blank"
                  _hover={{
                    transform: "scale(1.05)",
                  }}
                  zIndex={3}
                >
                  <Image src="/images/google-play.webp" />
                </Link>
              </HStack>
            </VStack>
          </HStack>
        </VStack>
      </HStack>
    </Flex>
  );
};
