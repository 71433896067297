import {
  HStack,
  Text,
  VStack,
  StackProps,
  Image,
  Flex,
  Link,
  Grid,
  GridItem,
  Box,
} from "@chakra-ui/react";

export const CallToStoreFooter = ({ ...rest }: StackProps) => {
  return (
    <Flex
      h={["300px", "300px", "300px", "300px", "660px"]}
      mt="250px"
      flexDirection={["column", "column", "column", "column", "row"]}
      justifyContent="start"
      alignItems="center"
      position="relative"
      {...rest}
    >
      <Image
        w="1664px"
        mt="-140px"
        position="absolute"
        display={["none", "none", "none", "none", "initial"]}
        objectFit="contain"
        objectPosition="center"
        alignSelf="flex-start"
        src="/images/bg-coins-footer.webp"
        right={0}
        zIndex={2}
      />
      <Image
        position="absolute"
        display={["none", "initial", "initial", "initial", "initial"]}
        objectFit="contain"
        objectPosition="center"
        alignSelf="flex-end"
        src="/images/coin.webp"
        top={["220px", "220px", "220px", "220px", "420px"]}
        left={["10px", "400px", "10px", "400px", "200px"]}
        zIndex={2}
      />
      <Box
        h="297px"
        width="100%"
        maxWidth="1584px"
        position="absolute"
        bgColor="brand.primary"
        borderTopRightRadius="full"
        borderBottomRightRadius="full"
      />
      <Grid
        position="relative"
        width="100%"
        gridTemplateAreas={[
          `"mobile"
        "infos"
        `,
          `"mobile"
      "infos"
      `,
          `"infos mobile"`,
          `"infos mobile"`,
          `"infos mobile"`,
        ]}
        objectFit="fill"
        zIndex={1}
      >
        <GridItem
          gridArea="infos"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems={["center", "center", "center", "center", "flex-end"]}
          rowGap="22px"
        >
          <VStack
            alignItems="flex-start"
            pl={["0px", "0px", "50px"]}
            pt={["0px", "0px", "50px", "0px", "0px"]}
          >
            <Text as="span" fontSize="25px" lineHeight="28px" fontWeight="700">
              Participe agora da
              <Text>comunidade Strings.</Text>
            </Text>
            <HStack>
              <VStack alignItems="start">
                <Text
                  as="span"
                  fontSize="15px"
                  lineHeight="14px"
                  fontWeight="800"
                  textTransform="uppercase"
                  color="brand.secondary"
                >
                  Baixar o<Text>aplicativo</Text>
                </Text>
                <HStack id="download-app">
                  <Link
                    href="https://apps.apple.com/br/app/strings/id1659828881"
                    target="_blank"
                    _hover={{
                      transform: "scale(1.05)",
                    }}
                  >
                    <Image src="/images/app-store.webp" />
                  </Link>
                  <Link
                    href="https://play.google.com/store/apps/details?id=com.stringsbrasil"
                    target="_blank"
                    _hover={{
                      transform: "scale(1.05)",
                    }}
                  >
                    <Image src="/images/google-play.webp" />
                  </Link>
                </HStack>
              </VStack>
            </HStack>
          </VStack>
        </GridItem>

        <GridItem
          gridArea="mobile"
          maxW={["400px", "400px", "700px", "700px", "700px"]}
          mt={["-200px", "-200px", "-200px", "-200px", "0"]}
          pt={["0px", "0px", "0px", "0px", "250px"]}
        >
          <Image
            position="relative"
            objectFit="contain"
            objectPosition="center"
            src="/images/mobile-trading.webp"
            zIndex={2}
          />
        </GridItem>
      </Grid>
    </Flex>
  );
};
